import "../Styles/Projects_main.css";
import Atropos from "atropos/react";
import { useEffect, useState } from "react";
import axios from "axios";
import Divider from "@mui/material/Divider";
import cancel_svg from "../assets/cancel.svg";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { OrbitProgress } from "react-loading-indicators";

function Projects_main() {
  const [Projects, setProjects] = useState([]);
  const [currentProject, setcurrentProject] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  var url = process.env.REACT_APP_WEBLINK;
  var url_five = process.env.REACT_APP_WEBLINK_FIVE;

  function Appearos(type) {
    axios
      .get(url_five + "/get_projects_front?type=" + type)
      .then((response) => {
        setProjects(response.data.projects); // Assume the response contains an array of project data
      })
      .catch((error) => {
        console.error("Error fetching projects:", error);
      });
  }

  function Overlayos(e) {
    const resultArray = [e.backgroundImages, e.link];
    const filteredArr = resultArray.filter(Boolean);
    e["combined"] = filteredArr.flat(2);
    setcurrentProject(e);
    console.log(e);

    if ((document.getElementById("Overlay").style.visibility = "hidden")) {
      document.getElementById("Overlay").style.visibility = "visible";
      document.getElementById("Overlay").style.pointerEvents = "auto";
      document.getElementById("Overlay").style.opacity = "1";

      document.body.style.overflow = "hidden";
    }
  }
  function removeOverlayos() {
    document.getElementById("Overlay").style.visibility = "hidden";
    document.getElementById("Overlay").style.pointerEvents = "none";
    document.body.style.overflow = "visible";
    document.getElementById("Overlay").style.opacity = "0";
    setTimeout(() => {
      setcurrentProject({});
    }, 10);
    setIsLoading(true);
  }

  useEffect(() => {
    Appearos("video");
  }, []);
  return (
    <>
      <div className="Overlay" id="Overlay">
        <img
          src={cancel_svg}
          alt=""
          srcset=""
          id="cancel_svg"
          onClick={removeOverlayos}
        />
        <Atropos>
          <div id="data_overlay">
            <div id="data_carousel">
              {currentProject && (
                <Carousel showStatus={false} emulateTouch={true}>
                  {currentProject["combined"]?.map((e, id) => {
                    if (!e?.includes(".png")) {
                      return (
                        <>
                          <div>
                            {isLoading && (
                              <div className="orbitProgress">
                                <OrbitProgress color="#2fb29e" size="medium" />
                              </div>
                            )}
                            <iframe
                              width="100%"
                              height="100%"
                              src={e}
                              frameborder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;  web-share"
                              referrerpolicy="strict-origin-when-cross-origin"
                              allowfullscreen
                              onLoad={() => setIsLoading(false)} // Set loading to false on load
                            ></iframe>
                          </div>
                        </>
                      );
                    } else {
                      return (
                        <>
                          <div>
                            <img
                              src={url + e}
                              alt="new"
                              className="Overlay_img "
                            />
                          </div>
                        </>
                      );
                    }
                  })}
                </Carousel>
              )}
            </div>
            <div id="data_text">
              <h2>{currentProject.title}</h2>
              <div
                dangerouslySetInnerHTML={{ __html: currentProject.description }}
              ></div>
            </div>
          </div>
        </Atropos>
      </div>
      <section className="Projects_main" id="Projects">
        <div className="star"></div>

        <div class="Projects_nav">
          <h2 onClick={() => Appearos("video")}>Vidéos</h2>
          <Divider
            orientation="vertical"
            flexItem
            variant="middle"
            sx={{
              opacity: 1,
              backgroundColor: "white",
              height: 50,
              top: 5,
              position: "relative",
            }}
          />

          <h2 onClick={() => Appearos("image")}>Graphisme</h2>
        </div>
        <div className="Projects" data-atropos-offset="2">
          {Projects.map((e) => {
            return (
              <div
                onClick={() => Overlayos(e)}
                className="Project fade-in"
                title={e.title}
              >
                <img src={url + e.backgroundImage} alt="new" />
                <h3>{e.title}</h3>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
}

export default Projects_main;
