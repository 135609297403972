import axios from "axios";
import "../Styles/Admin.css";
import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useEffect } from "react";
var url = process.env.REACT_APP_WEBLINK;
var url_five = process.env.REACT_APP_WEBLINK_FIVE;

function Admin() {
  const [formData, setFormData] = useState({
    type: "video", // Default type
    title: "",
    description: "",
    link: "",
    backgroundImages: [],
    backgroundImage: null, // For video
  });
  // Fetch existing projects when the component mounts
  useEffect(() => {
    getProjects();
  }, []);
  const [previewImages, setPreviewImages] = useState([]);
  const [previewImage, setPreviewImage] = useState(null);
  const [projects, setProjects] = useState([]); // Store all projects

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name == "type") {
      setFormData({
        type: value, // Default type
        title: "",
        description: "",
        link: "",
        backgroundImages: [],
        backgroundImage: null, // For video
      });
      formData.backgroundImage = null;
      formData.backgroundImages = [];
      formData.link = "";
      formData.title = "";
      formData.description = "";
    } else setFormData({ ...formData, [name]: value });
  };
  const getProjects = () => {
    axios
      .get(url_five + "/get_projects")
      .then((response) => {
        console.log(response.data);
        setProjects(response.data.projects); // Assume the response contains an array of project data
      })
      .catch((error) => {
        console.error("Error fetching projects:", error);
      });
  };
  const handleQuillChange = (value) => {
    setFormData({ ...formData, description: value });
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setFormData({ ...formData, backgroundImages: files });

    const previews = files.map((file) => {
      const reader = new FileReader();
      return new Promise((resolve) => {
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(file);
      });
    });

    Promise.all(previews).then((images) => setPreviewImages(images));
  };

  const handleSingleImageChange = (e) => {
    const file = e.target.files[0];
    setFormData({ ...formData, backgroundImage: file });
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setPreviewImage(null);
    }
  };
  function deleteProject(projectTitle) {
    // Construct the URL with the project title as a query parameter
    const url = url_five + `/delete_project?title=${projectTitle}`;

    // Send DELETE request using Axios
    axios
      .delete(url)
      .then((response) => {
        // Handle the response here
        console.log("Success:", response.data.message);
        getProjects();
        alert(response.data.message); // Optionally alert the user
      })
      .catch((error) => {
        // Handle errors (network issues, project not found, etc.)
        if (error.response) {
          console.log("Error:", error.response.data.message);
          alert(error.response.data.message); // Optionally alert the user
        } else {
          console.error("Error:", error.message);
          alert("There was an issue with the request.");
        }
      });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(url_five + "/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        getProjects();
        alert("Success");
      })
      .catch((error) => {
        alert("Error");
      });
    console.log("Form Data:", formData);
  };

  return (
    <div className="admin-form-container">
      <h1>Admin Actigularus</h1>
      <form onSubmit={handleSubmit} className="admin-form">
        <div className="form-group">
          <label htmlFor="type">Type</label>
          <select
            id="type"
            name="type"
            value={formData.type}
            onChange={handleInputChange}
          >
            <option value="video">Video</option>
            <option value="image">Image</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="title">Title</label>
          <input
            type="text"
            id="title"
            name="title"
            value={formData.title}
            onChange={handleInputChange}
            placeholder="Enter title"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="description">Description</label>
          <ReactQuill
            theme="snow"
            value={formData.description}
            onChange={handleQuillChange}
            placeholder="Write a description..."
          />
        </div>

        {formData.type === "video" && (
          <div className="form-group">
            <label htmlFor="link">Link</label>
            <input
              type="url"
              id="link"
              name="link"
              value={formData.link}
              onChange={handleInputChange}
              placeholder="Enter a link"
            />
          </div>
        )}

        <div className="form-group">
          <label htmlFor="backgroundImage">Background Image</label>
          <input
            type="file"
            id="backgroundImage"
            name="backgroundImage"
            accept="image/png"
            onChange={handleSingleImageChange}
          />
          {previewImage && (
            <div className="image-preview">
              <p>Preview:</p>
              <img
                src={previewImage}
                alt="Background Preview"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  marginTop: "10px",
                }}
              />
            </div>
          )}
        </div>

        {formData.type === "image" && (
          <div className="form-group">
            <label htmlFor="backgroundImages">Other Images</label>
            <input
              type="file"
              id="backgroundImages"
              name="backgroundImages"
              accept="image/png"
              multiple
              onChange={handleImageChange}
            />
            {previewImages.length > 0 && (
              <div className="images-preview">
                <p>Previews:</p>
                <div>
                  {previewImages.map((src, index) => (
                    <img
                      key={index}
                      src={src}
                      alt={`Background Preview ${index + 1}`}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        )}

        <button type="submit" className="submit-button">
          Submit
        </button>
      </form>
      {/* Preview of all projects */}
      <div className="projects-preview">
        <h2>Existing Projects</h2>
        <div className="projects-list">
          {projects.map((project) => (
            <div key={project.title} className="image-preview">
              <img src={url + project.backgroundImage} alt={project.title} />
              <h3>{project.title}</h3>
              <button onClick={() => deleteProject(project.title)}>
                Delete
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Admin;
